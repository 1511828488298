


@font-face {
  font-family: AvenirBook;
  src: url(../src/fonts/AvenirLTPro-Book.otf);
}

@font-face {
  font-family: AvenirHeavy;
  src: url(../src/fonts/AvenirLTPro-Heavy.otf);

}

@font-face {
  font-family: AvenirLight;
  src: url(../src/fonts/AvenirLTPro-Light.otf);
}

@font-face {
  font-family: AvenirMedium;
  src: url(../src/fonts/AvenirLTPro-Medium.otf);
}

@font-face {
  font-family: AvenirRoman;
  src: url(../src/fonts/AvenirLTPro-Roman.otf);
}

@font-face {
  font-family: JosefinSans-Bold;
  src: url(../src/fonts/JosefinSans-Bold.ttf);
}

@font-face {
  font-family: JosefinSans-Light;
  src: url(../src/fonts/JosefinSans-Light.ttf);
}

@font-face {
  font-family: Sansation-Bold;
  src: url(../src/fonts/Sansation-Bold.ttf);
}

@font-face {
  font-family: Sansation-Regular;
  src: url(../src/fonts/Sansation-Regular.ttf);
}

@font-face {
  font-family: Titillium-Bold;
  src: url(../src/fonts/Titillium-Bold.otf);
}

@font-face {
  font-family: Titillium-BoldUpright;
  src: url(../src/fonts/Titillium-BoldUpright.otf);
}

#root{
  overflow-x: hidden;
  height: 100%;
  font-family: "Sansation-Regular";

}

a{
  text-decoration: none;

}

.header{
  height: 74%;
  position: relative;
  z-index: 3;
}

.navbar-main{
  height: 12%;
  position: fixed;
  z-index: 9;
  width: calc(100% - 20px);
}



.header-bar-full{
  margin-top: 0px;
  padding: 0px 0px;
  /* background-color:white; */
  /* box-shadow: 0 10px 0px rgb(0 0 0 / 0%); */
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  right: 0;
  left: 0;
  justify-content: flex-start;
  height: 60px;

}

.container{
  flex-wrap: nowrap;
  justify-content: space-between;
  /* max-width: 85%; */
  /* width: 100%; */
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
  
}

.containerTwo{

  flex-wrap: nowrap;
  justify-content: space-between;
  /* max-width: 85%; */
  /* width: 100%; */
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}

.logo-click-link-css{
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 7px;
  margin-right: 0px;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  text-decoration: none !important;
  outline: none !important;
  background-color: transparent;
}

.mapImage{
  height: 70%;
  width: 70%

}
.img {
  vertical-align: middle;
    border-style: none;
    color: #fff !important;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase!important;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    height: 65px;
    margin-left: 94px;
    margin-top: -12px;
}

.contact-bar{
  position: absolute;
  display: flex!important;
  flex-basis: auto;
  align-items: center;
  margin-top: 6px;
  margin-left: 800px;
  top: 9px;

}

.ul-class-design{
  flex-direction: row;
  border-bottom-width: initial;
  border-bottom-style: none;
  border-bottom-color: initial;
  margin-left: auto!important;  
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  margin-top: 0;
  align-items: center;
  padding-inline-start: 159px;
  cursor: pointer;
}

.li-class{
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: none;
    margin-right: 21px;
}

.contact{
  color: #fff;
    padding-right: .5rem;
    padding-left: .5rem;
    font-size: 28px;
    background-color: transparent !important;
    padding: 0px 0px;
    margin: 0px 0px;;
    transition: all 0.4s;
    display: block;
    text-align: -webkit-match-parent;
    font-family: Avenir;
}

.login{
  color: #fff;
    padding-right: .5rem;
    padding-left: .5rem;
    font-size: 28px;
    background-color: transparent !important;
    padding: 0px 0px;
    margin: 0px 0px;;
    transition: all 0.4s;
    display: block;
    text-align: -webkit-match-parent;
    font-family: Avenir;
}

.register{
  color: #fff;
    padding-right: .5rem;
    padding-left: .5rem;
    font-size: 28px;
    background-color: transparent !important;
    padding: 0px 0px;
    margin: 0px 0px;;
    transition: all 0.4s;
    display: block;
    text-align: -webkit-match-parent;
    font-family: Avenir;
}

.background-display{

  height: 100%;
  padding: 0px;
  margin: 0px;
  /* overflow: hidden; */
  background-color: white;
  display: block;
}

.contact:hover{
  font-size: 50px;
  font-style: italic;
  background-color: blueviolet;
  color: blue;
}


.login:hover{
  font-size: 50px;
  font-style: italic;
  background-color: blueviolet;
  color: blue;
}




.class1{
  position: absolute; 
  top: 50%; left: 50%; 
  transform: translate(-50%, -50%); 
  color: white; 
  font-family: sans-serif; 
  text-align: center; 
  text-shadow: 0 0 20px rgba(0,0,0,0.5);
}

.class1h1{
  margin-top: 0; 
  margin-bottom: 0.8vw;
  font-size: 5vw;
  font-weight: bold;
  color: G;
}

.class1h1p{
  font-size: 2vw;
  font-weight: 100;
  margin-top: 0;
  color: red;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 200px;
    height: 45px;
    font-family: AvenirHeavy;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: rgb(58, 30, 219);
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    left: -124px;
    outline: none;
    position: relative;
  }

  .button{
    margin-top: -2px !important;
    margin-right: 13px !important;
    padding-right: 7px !important;
    border-radius: 61px !important;

}  



.button:hover {
  /* background-color: #175cdb; */
  /* box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4); */
  color: #fff;
  transform: translateY(-7px);
}

.sectionsecond{

  margin-top: 300
px;
}

.fullHeaderBar{

  margin-top: -2px !important;
    margin-right: 13px !important;
    padding-right: 7px !important;
    border-radius: 61px !important;
    background-color: white;
}

.sectionTwo{

  margin-top:auto;
  margin-bottom: 0px !important;
  padding: 0px 15px 0px 0px !important;
  z-index: 1 !important;
  position: relative !important;
  display: contents !important;
}

.social-container {
  background: #eee;
    /* padding: 0px 0px; */
    /* padding: 0px 0px 0px; */
    position: fixed;
    margin-right: -9px;
    width: 0px;
    color: white;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
  color: white !important;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color:black;
}

a.twitter {
  color: black;
}

a.instagram {
  color: black;
}

a.whatsapp {
  color: black;
}

a.phone{
  color: #000;
  display: flex;
}






#section{
  height: 80%;
  background-color: white;
  position: relative;

}

#socialF{
  margin-top: 1px;
  margin-right: 0%;
  position: relative;
  right: 23%;
}

#socialI{
  margin-top: 1px;
    margin-right: 0;
    position: relative;
    right: 26%;
}

#socialW{
  margin-top: 1px;
  margin-right: 0;
  position: relative;
  right: 29%;
}

#socialL{
  margin-top: 1px;
  margin-right: 0;
  position: relative;
  right: 32%;
}


#socialP{
  margin-top: -1px;
    /* margin-right: -52px; */
    position: relative;
    right: 865%;
  

}

#socialWorld{
  margin-top: 1px;
  margin-right: 0;
  position: relative;
  right: 700%;

}

.contactspan{
  font-size: 18px;
  color: white;
  font-family: Avenir;
  padding: 10px;
  position: relative;
  right: 71px
  
  
}

.contactspan:hover{
  visibility: visible;
  width: 100%;
  transition: all 0.3s ease-in-out;
  text-decoration: underline;
}

.slider-content{
  text-align: left;
  box-sizing: border-box;
  height: 100%;
}

.hslidercontent{
    font-family: Avenir;
    font-size: 60px;
    color: white;
    margin-bottom: 25px;
    text-transform: uppercase;
    height: 100%;
    position:absolute;
    top: 79px;
    right: 260px !important;
}

.small{
  font-family: 'Avenir';
    font-size: 17px;
    color: cornsilk;
    display: block;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-weight: 600;

}

.social-container a svg{
  font-size: 1.5em;
 
}


.appointment{
 height: 100%;
 width: 50%;
}


.bookImage{
  z-index: 1;
  background-position: top center;
  background-repeat: no-repeat;
  filter: blur(8px);
 -webkit-filter: blur(8px);
  
 background-size: cover;
 height: 100%;
}

.aboutText{
  background-color: rgb(0,121,163) !important;
  height: 90% !important;
  display: flex;
  align-items: center;
  display: flex;
  color: white;
  position: relative;
    top: 9px;
}


.deals {
  position: relative;
  width: 50%;
  height: 100%;
  
}

.imageDeals {

  display: block;
  width: 50%;
  height: 100%;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middleDeals {
  
    position: absolute;
    top: 50%;
    left: 71%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width: 101%;
}





.dealspan{

  font-weight: bold;
    font-family: Avenir;
    font-size: 50px;
    color: white;
    text-align: left !important;
    /* width: 150%; */
    padding: 0px;
    position: absolute;
    top: -82px;
    left: -37px;
}

.accesssapn{
  font-family: Avenir;
  font-size: 21px;
  color: white;
  width: 187%;
  text-align: left !important;
  position: relative;
  left: -131px;
  

}

#bookApp{

  background-color: teal;

}


  #footer {
    padding-top: 0px;
    background-color: #b5b5b52e;
    position: relative;
    /* margin-top: 12%; */
}


.main-footer {
  height: 70%;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 57px 0px;
  margin-top: 12%;
}

.footer-li{

  font-family: 'Sansation-Bold';
    color: white;
    font-size: 15px;
    line-height: 36px;
}

.beforeHeader{
  background-color: rgb(184,11 , 37);
  display: flex;
  height: 12%;
  padding: 13px 0px;
}

.logoHome{

  display: flex;
    background-color: white;
    border-style: hidden;
    box-shadow: 0px 0px 3px black;
}

.ultabbar{
  display: flex;
  position: relative;
  margin-top: 9px !important;
  margin-bottom: 0px !important;
  margin-left: 40%;
  align-items: end;
  justify-items: flex-end;
}

.HomeBar{
  padding-top: 15px;
    padding-right: 0px;
    font-size: 1rem;
    position: relative;
    right: 40%;
    color: #000;
    width: 100px;
}

.logout{
  position: relative;
    margin-left: 30px;
}

.contactNumber{

  color: white;
  position: absolute;
  right: 79%;
  font-family: 'Gill Sans';
}

.contactMail{

  color: white;
  position: absolute;
  right: 89%;
  font-family: 'Gill Sans';

}

.whatEFB {
  width: 30%;
  position: relative;
  margin-left: 76px;
  margin-top: 0;
}

.ourService {
  position: relative;
  padding: 0px 100px;
  width: 30%;
}

.Hone{
  padding: 0px 0px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  width: 100%;
  font-family: 'Sansation-Regular';
  font-size: 25px;
  color: white;

}

.para {
  margin-top: 9px;
  font-family: 'Titillium-Bold';
  font-size: 15px;
  color: white;
  width: 100%;
}

.ourOffice{
  position: relative;

  width: 30%;
}

.social-footer{

  display: flex;
  padding-right: 100px;

}


.working-process{

  position: relative;
    top: 40px;
    right: -84px;
    font-family: 'AvenirMedium';

}

.uClass{

  font-family: 'AvenirHeavy' !important;
  font-size: 150%;
}

#list{

  list-style: faEmpire;
}

.firstImage{

  position: relative;
  height: 100%;


}

#search{
  position: relative;
  top: -20%;
  cursor: pointer;

}

.login-button {
   display: inline-block;
    background-color:rgb(184,11 , 37);
    color: white;
    margin-right: .5em;
    height: 100%;
    width: 157%;
    position: relative;
    bottom: 24%;
    left: -44%;
    /* border-radius: 29px; */
    border-style: inset; 
    /* font-family: "AvenirHeavy"; */
    font-size: 18px;
    cursor: pointer ;
    /* border-bottom: 10px; */
    
}
  /* .login-button:hover {
    background-color: White;
    color: rgb(184,11 , 37);
    font-size: 18px;
  } */
  .login-button :active {
    background: blue; 
    box-shadow: inset 0px 1px 1px fadeout(black, 90%); 
  }
  .login-button :focus {
    outline: none;
  }

  .open-account  {
  display: inline-block;
  background-color:rgb(184,11 , 37);
  color: white;
  margin-right: .5em;
  height: 100%;
  width: 130%;
  position: relative;
  bottom: 24%;
  left: -15%;
  /* border-radius: 29px; */
  border-style: inset; 
  /* font-family: "AvenirHeavy"; */
  font-size: 18px;
  cursor: pointer;
  
}

.fisrt-image-text{
  font-family: Titillium-Bold;
  /* font-family: Titillium-BoldUpright; */
  color: white;
  position: relative;
  top: 25%;
  right: -10%;
  font-size: 35px;
}

.sentence{
  font-family: 'Sansation-Regular';
  font-size: 25px;
  color:white;
}

.first-image-appointment{
  position: relative;
  margin-top: 150px;
  left: 10%;
}

.first-appointment-button{
  position: relative;
    padding: 15px 60px;
    font-family: 'Sansation-Regular';
    font-size: 18px;
    background: rgb(0,135,181);
    color: white;
    cursor: pointer;
}

.first-appointment-button:hover{
  background: rgb(7, 101, 243);
  color: white;
}

.slider-section{
  height: 80% !important;
}

.choose-section{

  height: 100%;
}

.about-home{
  background-color: rgb(10 10 218 / 67%) !important;
    display: flex;
    align-items: center;
    margin-top: 78px; 
    margin-bottom: 50px;
    /* padding: 67px 0px;*/

}

.about-home-one{
    position: relative;
    /* height: 100%; */
    width: 100%;
    display: flex;
    align-items: center;
  
}

.about-home-two{
  position: relative;
  width: 50%;
  padding-left: 10%;
  height: 100%;
}


.about-home-img{
  position: relative;
    width: 50%;
    height: 88%;
    padding-left: 8%;
    top: 6%;
}

.about-img{

  position: relative;
  height: 85%;
  border-radius: 16px;
  width: 80%;
  


}

.about-header{
  font-family: 'Sansation-Bold';
    font-size: 39px;
    color: white;

}

.about-para{

  font-family: 'Sansation-Regular';
    font-size: 18px;
    color: white;

}

.svg-inline--fa.fa-w-14 {
  width: 1.875em;
}

.ul-about-home{

    line-height: 30px;
    color: white;
}

.li-about-home{

  font-family: 'JosefinSans-Light';
    font-size: 18px;
}

.app-header-dv{

  height: 100%;
    position: relative;
    width: 100%;
    
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-slider-shadow{

  height: 100%;
  margin-right: 20px;
  margin-left: 20px;
  border-style: solid;
  border-radius: 40px;
  border-color: white;
  background-color: #ffffff !important;
  z-index: 9999;
}

.section-slider-shadow:hover{

  box-shadow: 0px 0px 4px black;
}

.credit-footer{
  height: 10%;
    background-color: black;
    display: flex;
    justify-content: center;
}

.footer-with-credit{
  display: contents;
}

.credit-text {
  color: white;
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16%;
}

.credit-p-tag {
  padding-right: 264px;
  margin-bottom: 0px;
  color: white;
}

.privacy-main-wrapper{
  position: relative;
    margin-top: 10%;
    padding: 0px 47px;
}

strong{
  text-align: center;
}


@media screen and (max-width: 660px) {

  .slider-section { 
    height: 80% !important;
}

  .beforeHeader {
    background-color: rgb(184,11 , 37);
    display: flex;
    height: 12%;
    padding: 13px 0px;
    position: relative;
}

.ul-class-design {
    
  
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
  width: 100% !important;
  position: relative;
  height: 100% !important;
  padding-inline-start: 0px !important; 
}

a.social {
  transition: transform 250ms;
  display: flex;
  color: white !important;
  font-size: 12px;
  width: 100%;
  position: relative;
  justify-content: center;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
  margin-top: 3px;
}

.img {
  vertical-align: middle;
  border-style: none;
  color: #fff !important;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase!important;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  height: 35px;
  margin-left: 4px;
  margin-top: -12px;
}


.ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down {
  font-size: 7px !important;
  vertical-align: baseline !important;
}

.navbar-main {
  height: 12% !important;
  position: fixed !important;
  z-index: 9 !important ;
  width: calc(100% - 0px) !important;
}



.HomeBar{
  /* padding-top: 15px; */
padding-right: 0px;
font-size: 8px;
position: relative;
/* right: 54%; */
color: #000;
width: 40px;
text-align: center;
}

#section {
  height: 40%;
  background-color: white;
  position: relative;
}

.firstImage {
  position: relative;
  height: 80%;
}

.fisrt-image-text {
  font-family: Titillium-Bold;
  color: white;
  position: relative;
  top: 35%;
  right: -12%;
  font-size: 11px;
}

.sentence {
  font-family: 'Sansation-Regular';
  font-size: 12px;
  color: white;

}
 
.first-image-appointment {
  position: relative;
  margin-top: 100px;
  margin-left: 12%;
}

.fisrt-image-text {
  font-family: Titillium-Bold;
  color: white;
  position: relative;
  margin-right: -12%;
  font-size: 11px;
  top: 90px !important;
}

.first-appointment-button {
  position: relative;
  padding: 3px 5px;
  font-family: 'Sansation-Regular';
  font-size: 10px;
  background: rgb(0,135,181);
  color: white;
  cursor: pointer;
}

.about-header {
  font-family: 'Sansation-Bold';
  font-size: 20px;
  color: white;
}

.myCalender{
  display: none !important;
}
.about-para {
  font-family: 'Sansation-Regular';
  font-size: 13px;
  color: white;
}

.ul-about-home {
  line-height: 18px;
  color: white;
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}

.about-home-img {
  position: relative;
  width: 50%;
  height: 88%;
  padding-left: 8%;
  top: 6%;
  display: none !important;
}

.li-about-home {
  font-family: 'Sansation-Regular';
  font-size: 13px;
}

.about-home-two {
  position: relative;
  width: 100%;
  padding-left: 10%;
  height: 100%;
}



.main-footer {
  height: 100%;
  display: block;
  align-items: flex-start;
  width: 100%;
  padding: 35px 0px;
  margin-top: 9%;
}

.social-footer {
  display: flex;
  padding-right: 0px;
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

#socialF {
  margin-top: 1px;
  margin-right: 0%;
  position: relative;
  right: 0%;
}



.para {
  margin-top: 9px;
  font-family: 'Sansation-Regular';
  font-size: 16px;
  color: white;
  width: 80%;
}


.Hone {
  padding: 0px 0px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 30px;
  font-family: 'Sansation-Regular';
  font-size: 20px;
  color: white;
}

.ourOffice {
  position: relative;
  width: 100%;
  padding: 0px 20px;
  right: 0%;
}


.credit-p-tag {
  padding-right: 38px;
  margin-bottom: 0px;
  color: white;
}

.footer-li {
  font-family: 'Sansation-Regular';
  color: white;
  font-size: 13px;
  line-height: 20px;
}

.whatEFB {
  width: 100%;
  position: relative;
  margin-left: 25px;
  margin-top: 0;
}



.ourService {
  position: relative;
  padding: 30px 22px;
  width: 100%;
}

.about-home {
  background-color: rgb(10 10 218 / 67%) !important;
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
padding: 0px 0px;
}

.credit-text {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}


.credit-p-tag{
  padding-right: 40px;
  margin-bottom: 0px;
  color: white;
}

}

@media screen and (max-width: 1200px) {

  .ultabbar {
    display: none !important;
    position: relative;
    /* margin-top: 9px !important; */
    margin-bottom: 0px !important;
    margin-left: 0;
    align-items: center;
    /* justify-items: center; */
    /* justify-content: center; */
    /* align-content: center; */
    /* align-content: center; */
    margin-right: 0px !important;
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 10px;
  }

  .myCalender{
    display: none !important;
  }
  
}
.ant-carousel .slick-list{
    margin-top: 50px !important;
} 

.c-class-wrapper-main{
    display: flex;
    position: relative;
    height: 100%;
}


.header-slider-wrapper{
	
	font-size: 35px;
    position: relative;

}

.paras-slider-wrapper{

	font-family: 'Sansation-Regular';
    font-size: 25px;
    text-align: justify;
    line-height: 35px;
}

.img-slider-class{
    position: relative;
    height: 100%;

}

.para-main-wrapper{
    padding: 0px 53px;
}

.slider-img-wrap{
    height: 100%;
    border-style: solid;
    border-radius: 15px;
    border-color: white;

}


.ant-carousel .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0 !important;
    left: 0;
    z-index: 3 !important;
    display: flex !important;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;
    list-style: none;
    margin-bottom: -63px !important;
    height: 42px !important;
}

.ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 16px;
    height: 20px;
    margin: 0 0px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
    color: black;
    background-color: black;
}

.ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 20px;
    padding: 0;
    color: white;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
}

@media  screen and (max-width: 660px) {

    .ant-carousel .slick-list {
        margin-top: 35px !important;
        height: 76%;
        border-style: solid;
        border-color: white;
        border-radius: 33px;
    }
    
}




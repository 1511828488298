.ant-drawer {
    position: fixed;
    z-index: 999999999999999999999;
    
}

.ant-drawer-content-wrapper{

    width: 40% !important;
    background-color: blue;
}

title{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ant-drawer-header {
    position: relative;
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    /* display: flex; */
    /* align-items: center; */
    /* justify-items: center; */
    background: #da1717bd !important;
}

.ant-drawer-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    font-family: 'Sansation-Bold';
    font-size: 40px !important;
    /* background: red; */
    color: white !important;
}


.ant-form {
    box-sizing: border-box !important;
    margin: 0;
    /* padding: 0; */
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 14px !important;
    font-variant: tabular-nums !important;
    line-height: 1.5715 !important;
    list-style: none !important;
    font-feature-settings: 'tnum', "tnum" !important;
    margin-top: 25px !important;
    /* margin-left: -79px !important; */
    padding: 0px 40px 0px 0px !important;
    overflow-y: hidden;
}

.ant-form-item-label > label {
    position: relative !important;
    display: inline-flex !important;
    align-items: center !important; 
    height: 32px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 18px !important  ;
    font-family: 'Sansation-Bold';
}

.anticon-close{
    display: none !important;
}

.r-form-wrapper{

    height: 118%;
    position: relative;
    top: 0%;
    width: 88%;
    left: 80px;
    line-height: 70px;
    border-style: solid;
    text-align: center;
    border-radius: 46px;
    background: white;


}

.r-form-hearder{

    position: relative;
}

.r-form-labels{
    position: relative;
    padding: 0px 0px;
    top: 8%;
    display: flex;
    align-items: center;
    justify-content: center;  
}
.align-name{
    position: relative;
    margin-right: -2%;
}

.contact-align{
    position: relative;
}

.align-password{
    margin-right: 10px;

}

.r-label-name{
    position: relative;
    padding: 15px 30px;
    font-family: 'Titillium-Bold';
    font-size: 25px !important;

}

.r-label-textfields{
    border-style: solid;
    border-color: black;
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
    font-size: 20px;
    font-family: 'Titillium-Bold';
    text-align: center;
    outline: none;
    width: 68%;
}

.r-signup-button{
        /* position: relative; */
        font-family: 'Titillium-Bold';
        font-size: 25px;
        background: rebeccapurple;
        border-style: solid;
        border-width: 0px;
        border-radius: 7px;
        /* top: 8%; */
        left: 11%;
        /* padding: 0px 19px; */
        height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:50%;
    
}

.r-side-login-button{

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15%;
    margin-right: -24%;
    height: 0; 
}

.signup-button:hover{
    cursor: pointer;
    background: rgb(124, 8, 240);   

}

.alert-box {
    height: 7%;
    position: relative;
    margin-left: 100px;
    /* display: flex; */
    align-items: center;
    margin-top: -81%;
    align-content: center;
    border-style: solid;
    width: 72%;
    font-family: 'JosefinSans-Light';
    color: white;
    background-color: #ff000066;
    font-size: 16px;
    box-shadow: 0px 0px 6px 0px #ff000066;
    border-color: transparent;
    border-radius: 7px;
}
.alert-box-pass{
    height: 7%;
    position: relative;
    margin-left: 100px;
    /* display: flex; */
    align-items: center;
    margin-top: -81%;
    align-content: center;
    border-style: solid;
    width: 72%;
    font-family: 'JosefinSans-Light';
    color: black;
    background-color: #0cec0466;
    font-size: 16px;
    box-shadow: 0px 0px 6px 0px #ff000066;
    border-color: transparent;
    border-radius: 7px; 
}
.alert-message{
    position: relative;
    text-align: center;
    align-items: center;
    padding: 0px 79px;
}



.form-section{
    height: 100%;
    border-style: solid;
    border-color: transparent;
    box-shadow: 0px 0px 5px 0px black;
    border-radius: 10px;
    background-color: white;
    

}

.ant-form-item-children-icon{
    display:hidden;
}

.ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;  
    background-color: white;

}

.ant-drawer-body {
    /* flex-grow: 0; */
    padding: 24px !important;
    overflow: hidden !important;
    font-size: 14px !important;
    /* line-height: 1.5715; */
    word-wrap: break-word !important;
    position: relative !important;
    margin-top: 1rem !important;
    margin-bottom: 0rem !important;
    margin-right: 0rem !important;
    /* background-color: red; */
/* background-image: linear-gradient(316deg, #ffe190 0%, #fa9e8c 74%);  */


}

/* .ant-drawer-close {
    
    display: none;
} */

.close-img{
    position: relative;
    width: 6%;
    cursor: pointer;
}

.close-img:hover{
    position: relative;
    width: 6%;
    cursor: pointer;
    transform:rotateZ(180deg);
}

.ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 46px;
}

.ant-btn{

    height: 50px !important;
}

.ant-btn-primary {
    color: #fff !important;
    background: #1890ff !important;
    border-color: #1890ff !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
    font-family: 'Sansation-Bold' !important;
    font-weight: normal !important;
    font-size: 23px !important;
    width: 70% !important;
    margin-top: 40px;
    /* padding-bottom: 7px; */
}

.ant-form-item {
    box-sizing: border-box !important;
    margin: 0 !important;
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 14px !important;
    font-variant: tabular-nums !important;
    line-height: 1.5715 !important;
    list-style: none !important;
    font-feature-settings: 'tnum', "tnum";
    margin-bottom: 24px !important;
    vertical-align: top !important;
}

.ant-input {
    box-sizing: border-box !important;
    margin: 0 !important;
    padding: 0 !important;
    font-variant: tabular-nums !important;
    list-style: none !important;
    font-feature-settings: 'tnum', "tnum";
    position: relative !important;
    display: inline-block !important;
    width: 90% !important;
    min-width: 0;
    padding: 4px 11px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 15px !important;
    line-height: 1.5715 !important;
    background-color: #fff !important;
    background-image: none !important;
    /* border: 1px solid #d9d9d9 !important; */
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    border-radius: 2px !important;
    transition: all 0.3s !important;
    font-family: 'Sansation-Regular' !important;
}

.ant-input-suffix {
    margin-left: 76px !important;
}

.ant-input-affix-wrapper {
    position: relative !important;
    display: inline-block !important;
    width: 90% !important;
    min-width: 0 !important;
    padding: 4px 11px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 14px !important;
    line-height: 1.5715 !important;
    background-color: #fff !important;
    background-image: none !important;
    /* border: 1px solid #d9d9d9; */
    border-radius: 2px !important;
    transition: all 0.3s !important;
    display: inline-flex !important;
    border-style: solid !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    border-width: 1px !important;
}

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    position: absolute;
    top: 50%;
    right: 155px;
    z-index: 1;
    width: 32px;
    height: 20px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    visibility: hidden !important;
    -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    pointer-events: none;
}

.alert-sign{
    position: relative;
    width: 8%;
    margin-left: -74px;
    display: flex;
    align-items: center;

}

.alert-text{
    position: relative;
    font-family: 'Sansation-Bold';
    color: red;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
}

.alert-pass-text{
    position: relative;
    font-family: 'Sansation-Bold';
    color: green;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;

}


.forgot{
    display: flex;
    justify-content: center;
    margin-left: 60px;
    font-size: 15px;
    text-decoration-line: underline;
    cursor: pointer;
}

.forgot:hover {
    display: flex;
    justify-content: center;
    margin-left: 60px;
    font-size: 15px;
    cursor: pointer;
    text-decoration-line: none;
    color: black;
    
}

.forgot-password{
    

}

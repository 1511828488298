.forgot-form-wrapper{
    height: 100%;
    position: relative;
}

.forgot-title{
    height: 20%;
    position: relative;
    margin-top: 146px !important;
    display: flex;
    justify-content: center;
    font-size: 28px;
}

.comman-class{
    padding: 0px 90px;
    margin-left: 100px;
  }
  
  .msg-header{
    margin-top: 20px ;
    display: flex;

  }

  .msg-body{
    width: 100%;
  }
  
  .button-message{
    padding: 0px 10px;
  }
  
  .button{
    padding: 0px 20px;
  }
  
  .form-class{
    margin-bottom: 10px;
  }

$font-base: 'Roboto', 'Arial', sans-serif;

#app {
	display: flex;
	height: 100vh;
	
	> div {
		margin: auto;
	}
}


.carousel__wrapper {
	overflow: hidden;
    width: 59%;
    position: relative;
    left: 5%;
    height: 100%;
    top: 96px;
}
.carousel__controls {
	width: 50%;
    display: flex;
}

.carousel__container {
	display: flex;
    height: 223px;
    position: relative;
    overflow: hidden;
    width: 50%;
}

.carousel__image {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	transform: scale(1.1);
	transition: all .15s ease-out;
	
	&.active {
		opacity: 1;
		transform: scale(1);
	}
}

.carousel__button {
	background: #f2f2f2;
	border: 0;
	color: purple;
	cursor: pointer;
	font-size: 1rem;
	flex-grow: 1;
	outline: none;
	padding: 1rem;
	transition: all .1s ease-out;
	
	&:hover {
		background: rgba(#f2f2f2, .8);
	}
}

.flavor-text {
	display: block;
	color: rgba(#fff, .5);
	font-size: .75rem;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 1.5;
	margin: 1rem auto;
	max-width: 100%;
	text-align: center;
	text-transform: uppercase;
	width: 15rem;
}



.slider-main-wrapper{
	display: flex;
    width: 90%;
}

.paragraph-slider-wrapper{

	position: relative;
    width: 85%;
    height: 100%;
    top: 77px;
    font-family: 'Titillium-Bold';
    left: -4%;
    


}

.header-slider-wrapper{
	
	font-size: 35px;
    position: relative;

}

.paras-slider-wrapper{

	font-family: 'Sansation-Regular';
    font-size: 25px;
    text-align: justify;
    line-height: 35px;
}

@font-face {
    font-family: AvenirBook;
    src: url(../fonts/AvenirLTPro-Book.otf);
  }
  
@font-face {
    font-family: AvenirHeavy;
    src: url(../fonts/AvenirLTPro-Heavy.otf);
  
  }
  
@font-face {
    font-family: AvenirLight;
    src: url(../fonts/AvenirLTPro-Light.otf);
  }
  
@font-face {
    font-family: AvenirMedium;
    src: url(../fonts/AvenirLTPro-Medium.otf);
  }
  
@font-face {
    font-family: AvenirRoman;
    src: url(../fonts/AvenirLTPro-Roman.otf);
  }
  
@font-face {
    font-family: JosefinSans-Bold;
    src: url(../fonts/JosefinSans-Bold.ttf);
  }
  
@font-face {
    font-family: JosefinSans-Light;
    src: url(../fonts/JosefinSans-Light.ttf);
  }
  
@font-face {
    font-family: Sansation-Bold;
    src: url(../fonts/Sansation-Bold.ttf);
  }
  
@font-face {
    font-family: Sansation-Regular;
    src: url(../fonts/Sansation-Regular.ttf);
  }
  
@font-face {
    font-family: Titillium-Bold;
    src: url(../fonts/Titillium-Bold.otf);
  }
  
@font-face {
    font-family: Titillium-BoldUpright;
    src: url(../fonts/Titillium-BoldUpright.otf);
  }


.choose{
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: 'Sansation-Regular';

}

.p-width{
  position: relative;
  width: 25%;
  padding: 25px 48px;
}

.experience{

  height:100%;
   
}

.experience-header{

  top: 20px;
    font-size: 1.125rem;
    font-family: "Sansation-Bold";
    position: relative;
}


.experience-img-header{

  display: flex;
  margin-bottom: 10px;

}

.experience-img{

  position: relative !important;
    height: 60px !important;
}

.best-offers{

  height: 100%;
    
}

.trust{
  line-height: 21px;

}

.service-header{
  position: relative; 

}

.side-menu{
  position: relative;
  margin-left: 0%;
  display: none;
}

.Side-Menu-ul-class {
  list-style: none;
  line-height: 80px;
  text-align: center;
  font-size: 20px;
  padding-inline-start: 0px !important;
}



.side-menu-li-class{

    color: black;
}

.side-menu-li-class:hover{
    border-style: solid !important;
    border-radius: 10px !important;
    color: orangered !important;
}

@media screen and (max-width: 660px) {

  


  .side-menu{
    position: relative;
    margin-left: 0%;
    display: flex;
  }

  .choose {
    position: relative;
    width: 100%;
    display: contents;
    margin-top: 50px;
    font-family: 'Sansation-Regular';
}

.p-width {
  position: relative;
  width: 100%;
  padding: 15px 48px;
}


.experience-header {
  /* top: 20px; */
  font-size: 15px !important;
  font-family: "Sansation-Bold" !important;
  position: relative !important;
  top: 0px;
  padding: 0px 14px;
}

.patgforchoose {
  font-size: 10px;
  line-height: 10px;
}

.experience-img {
  position: relative !important;
  height: 20px !important;
}


.experience-header {
  /* top: 20px; */
  font-size: 10px !important;
  font-family: "Sansation-Bold" !important;
  position: relative !important;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 8px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: normal !important;
  font-size: 11px !important;
  line-height: 13px !important;
  white-space: nowrap !important;
  cursor: pointer !important;
  transition: all 0.3s !important;
}
  
}

@media screen and (max-width: 1200px) {

  .side-menu{
    position: relative;
    margin-left: 0%;
    display: flex;
  }

  ul{
    list-style: none;
  }

  li:before {
    content: "★ ";
  }
  
}
.uapp-header{
    height: 20%;
}

.uapp-title-main{
    height: 10%;
}

.uapp-title{
    position: absolute;
    margin-left: 30px;
}

.uapp-table-main-div{
    padding: 0px 40px;
}
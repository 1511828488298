.provized_social_float {
    font-family: FontAwesome;
    position: fixed;
    top: 50%;
    left: -25px;
    line-height:40px;
    font-size: 20px;
    opacity: .4;
    -webkit-animation: linear social_float_off .3s;
    margin-top: -175px;
    box-sizing: content-box;
}
.provized_social_float:hover {
    left: -5px;
    opacity: 1;
    -webkit-animation: linear social_float_on .3s;
}
@-webkit-keyframes social_float_on{
    0% {left: -25px;opacity: .5;}
    100% {left: -5px;opacity: 1;}
}
@-webkit-keyframes social_float_off{
    0% {left: -5px;opacity: 1;}
    100% {left: -25px;opacity: .5;}
}
a.provized_social_icon {
    position: relative;
    padding: 5px 8px 5px 15px;
    color: white;
    background-color:rgba(66,66,66,.5);
    border-radius: 5px;
    margin: 5px 0;
    display:list-item;
    opacity: .9;
    
    text-align: center;
    -webkit-animation: linear social_icon_off .3s;
    text-shadow: 0px 0px 2px #fff;
}
a.provized_social_icon:hover {
    opacity: 1;
    text-shadow: 0px 0px 5px #fff;
    -webkit-animation: linear social_icon_on .3s;
}
 @-webkit-keyframes social_icon_on {
    0% {opacity: .4}
    100% {opacity: 1}
}
@-webkit-keyframes social_icon_off {
    0% {opacity: 1}
    100% {opacity: .4}
}

#instagram {
    display: inline-block;
    color: #fff;
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  }

  @media screen and (max-width:660px){

    .provized_social_float {
       
        position: fixed;
        top: 50%;
        left: -15px;
        line-height: 5px;
        font-size: 8px;
        opacity: .4;
        -webkit-animation: linear social_float_off .3s;
        margin-top: -174px;
        box-sizing: content-box;
    }
    

  }


.sectionAbout{

    height: 50%;

}

.aboutImg{

    height: 100%;
    position: relative;
    top: 66px;

}

.aboutTop{
    height:100%;
}

.aboutPageText{
    position: relative;
    top: 116px;
    text-align: center;
    /* font-family: 'Avenir'; */
    /* font-size: 90px; */
    margin-top: 0;
    margin-bottom: 0;
    color: white;
    font-family: 'Sansation-Bold';
    font-size: 52px;
}

.aboutBody{

    height: 80%;
    position: relative;
    width: 50%;
    /* padding-left: 5px; */
    right: -41px;
    
}

.aboutPageHeader{
    width:50%;
    height: 80%;
    background-color: rgb(0,119,160);
}

.sectionTwoabout{

    display: flex;
    height: 103%;
    position: relative;
    margin-top: 160px;
    margin-bottom: -70px;
}

.headingAbout{

position: relative;
/* left: 25%; */
display: flex;
font-family: 'Sansation-Bold';
font-size: 1.75rem; 
display: flex;
justify-content: center;
}

.ulsectTwoAbout{

    padding: 0px 0px;
    width: 80%;
    position: relative;
    display: contents;
    justify-content: center;

}

.ilsectTwoAbout{

    padding: 20px 120px;
    color: white;
    font-size: 1rem;

}

.aboutFooter {
    margin-top: -180px;
    position: relative;
}

.abouthead-para{
    position: relative;
    margin-top: 0;
}



@media screen and (max-width:660px) {
    .abouthead-para{
        margin-top: 200;
    }

    .ulsectTwoAbout {
        padding: 0px 0px;
        width: 80%;
        position: relative;
        display: contents;
        justify-content: center;
        list-style: none;
    }

    .ilsectTwoAbout {
        padding: 20px 53px;
        color: white;
        font-size: 15px;
    }

    .aboutBody {
        height: 80%;
        position: relative;
        width: 50%;
        right: -41px;
        display: none;
    }

    .sectionTwoabout {
        display: flex;
        height: 100%;
        position: relative;
        margin-top: 100px;
        margin-bottom: 0px;
    }

    
    
}


@media screen and (max-width: 660px) {
    .ilsectTwoAbout {
        padding: 10px 40px;
        color: white;
        font-size: 15px
    }

    .aboutBody {
        height: 80%;
        position: relative;
        width: 50%;
        /* padding-left: 5px; */
        right: -41px;
        display: none;
    }
    .aboutPageHeader {
        width: 100%;
        height: 80%;
        background-color: rgb(0,119,160);
    }

    .ilsectTwoAbout {
        padding: 10px 20px;
        color: white;
        font-size: 1rem;
    }
    .sectionTwoabout {
        display: flex;
        height: 80%;
        position: relative;
        margin-top: 100px;
        /* margin-bottom: -70px; */
    }
    .aboutFooter {
        margin-top: -100px;
        position: relative;
    }
}
@font-face {
  font-family: Sansation-Bold;
  src: url(../fonts/Sansation-Bold.ttf);
}

@font-face {
  font-family: Sansation-Regular;
  src: url(../fonts/Sansation-Regular.ttf);
}



.ant-dropdown-menu-item{
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 1rem;
  line-height: 30px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  font-family: "Sansation-Regular";
}

.ant-dropdown-link{
 color: black;
}
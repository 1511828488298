#ft-form {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15
  }
  #ft-form *,
  #ft-form ::after,
  #ft-form ::before {
    box-sizing: border-box
  }
  #ft-form input,
  #ft-form select,
  #ft-form textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
  }
  #ft-form select {
    text-transform: none
  }
  #ft-form [type=submit] {
    -webkit-appearance: button
  }
  #ft-form legend {
    padding: 0
  }
  #ft-form h2,
  #ft-form p {
    margin: 0
  }
  #ft-form fieldset {
    margin: 0;
    padding: 0
  }
  #ft-form html {
    font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    line-height: 1.5
  }
  #ft-form body {
    font-family: inherit;
    line-height: inherit
  }
  #ft-form *,
  #ft-form ::after,
  #ft-form ::before {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
  }
  #ft-form textarea {
    resize: vertical
  }
  #ft-form input::-moz-placeholder,
  #ft-form textarea::-moz-placeholder {
    color: #9ca3af
  }
  #ft-form input:-ms-input-placeholder,
  #ft-form textarea:-ms-input-placeholder {
    color: #9ca3af
  }
  #ft-form input::placeholder,
  #ft-form textarea::placeholder {
    color: #9ca3af
  }
  #ft-form h2 {
    font-size: inherit;
    font-weight: inherit
  }
  #ft-form input,
  #ft-form select,
  #ft-form textarea {
    padding: 0;
    line-height: inherit;
    color: inherit
  }
  #ft-form [type=date],
  #ft-form [type=email],
  #ft-form [type=tel],
  #ft-form [type=text],
  #ft-form select,
  #ft-form textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding-top: .5rem;
    padding-right: .75rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
    font-size: 1rem;
    line-height: 1.5rem
  }
  #ft-form [type=date]:focus,
  #ft-form [type=email]:focus,
  #ft-form [type=tel]:focus,
  #ft-form [type=text]:focus,
  #ft-form select:focus,
  #ft-form textarea:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px rgba(199,210,254,.5), 0 0 #0000;
    border-color: #2563eb
  }
  #ft-form input::-moz-placeholder,
  #ft-form textarea::-moz-placeholder {
    color: #6b7280;
    opacity: 1
  }
  #ft-form input:-ms-input-placeholder,
  #ft-form textarea:-ms-input-placeholder {
    color: #6b7280;
    opacity: 1
  }
  #ft-form input::placeholder,
  #ft-form textarea::placeholder {
    color: #6b7280;
    opacity: 1
  }
  #ft-form select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right .5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact
  }
  #ft-form [type=checkbox],
  #ft-form [type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px
  }
  #ft-form [type=checkbox] {
    border-radius: 0
  }
  #ft-form [type=radio] {
    border-radius: 100%
  }
  #ft-form [type=checkbox]:focus,
  #ft-form [type=radio]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px rgba(199,210,254,.5), 0 0 #0000;
  }
  #ft-form [type=checkbox]:checked,
  #ft-form [type=radio]:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
  }
  #ft-form [type=checkbox]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")
  }
  #ft-form [type=radio]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e")
  }
  #ft-form [type=checkbox]:checked:focus,
  #ft-form [type=checkbox]:checked:hover,
  #ft-form [type=radio]:checked:focus,
  #ft-form [type=radio]:checked:hover {
    border-color: transparent;
    background-color: currentColor
  }
  #ft-form [type=checkbox]:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
  }
  #ft-form [type=checkbox]:indeterminate:focus,
  #ft-form [type=checkbox]:indeterminate:hover {
    border-color: transparent;
    background-color: currentColor
  }
  #ft-form [type=file] {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: unset;
    line-height: inherit
  }
  #ft-form [type=file]:focus {
    outline: 1px auto -webkit-focus-ring-color
  }
  #ft-form fieldset {
    font-size: 100%;
    margin-top: 3rem;
    margin-bottom: 1.5rem
  }
  #ft-form fieldset:first-child {
    margin-top: 0
  }
  #ft-form fieldset > * {
    display: block;
    margin-bottom: 1.5rem
  }
  #ft-form fieldset > :last-child {
    margin-bottom: 0
  }
  #ft-form fieldset > .two-cols > * {
    display: block;
    margin-bottom: 1.5rem
  }
  #ft-form fieldset > .two-cols > :last-child {
    margin-bottom: 0
  }
  @media only screen and (min-width:640px) {



    #ft-form fieldset > .two-cols {
      display: flex;
      align-items: flex-end
    }
    #ft-form fieldset > .two-cols > * {
      display: block;
      margin-right: 1.5rem;
      margin-top: 0;
      margin-bottom: 0;
      flex: 1
    }
    #ft-form fieldset > .two-cols > :last-child {
      margin-right: 0
    }
  }
  #ft-form fieldset div > label {
    display: inline-flex;
    align-items: flex-start;
    margin-top: .5rem;
    width: 100%
  }
  #ft-form fieldset div > label:last-child {
    margin: .5rem 0 0 0
  }
  #ft-form fieldset div.inline {
    padding: .55rem 0 0;
    width: 100%
  }
  #ft-form fieldset div.inline > label {
    width: auto;
    margin-right: .5rem
  }
  #ft-form fieldset div.inline > label:last-child {
    margin-right: 0
  }
  #ft-form fieldset > .two-cols div.inline {
    padding: .55rem 0
  }
  #ft-form fieldset > legend {
    font-weight: 700;
    font-size: 120%;
    margin-bottom: 1rem
  }
  #ft-form fieldset > p {
    margin: 0
  }
  #ft-form [type=file] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding-top: .5rem;
    padding-right: .75rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
    font-size: 1rem;
    line-height: 1.5rem
  }
  #ft-form [type=file]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px rgba(199,210,254,.5), 0 0 #0000;
    border-color: #2563eb
  }
  #ft-form [multiple],
  #ft-form [type=date],
  #ft-form [type=datetime-local],
  #ft-form [type=email],
  #ft-form [type=file],
  #ft-form [type=month],
  #ft-form [type=number],
  #ft-form [type=password],
  #ft-form [type=search],
  #ft-form [type=tel],
  #ft-form [type=text],
  #ft-form [type=time],
  #ft-form [type=url],
  #ft-form [type=week],
  #ft-form select,
  #ft-form textarea {
    border-radius: .375rem;
    margin-top: .25rem;
    box-shadow: 0 0 #0000,0 0 #0000,0 1px 2px 0 rgba(0,0,0,.05);
    border-color: #bbb;
    width: 100%
  }
  #ft-form [multiple] select,
  #ft-form [type=date] select,
  #ft-form [type=datetime-local] select,
  #ft-form [type=email] select,
  #ft-form [type=file] select,
  #ft-form [type=month] select,
  #ft-form [type=number] select,
  #ft-form [type=password] select,
  #ft-form [type=search] select,
  #ft-form [type=tel] select,
  #ft-form [type=text] select,
  #ft-form [type=time] select,
  #ft-form [type=url] select,
  #ft-form [type=week] select,
  #ft-form select select,
  #ft-form textarea select {
    padding-right: 2.5rem
  }
  #ft-form [multiple]:focus,
  #ft-form [type=date]:focus,
  #ft-form [type=datetime-local]:focus,
  #ft-form [type=email]:focus,
  #ft-form [type=file]:focus,
  #ft-form [type=month]:focus,
  #ft-form [type=number]:focus,
  #ft-form [type=password]:focus,
  #ft-form [type=search]:focus,
  #ft-form [type=tel]:focus,
  #ft-form [type=text]:focus,
  #ft-form [type=time]:focus,
  #ft-form [type=url]:focus,
  #ft-form [type=week]:focus,
  #ft-form select:focus,
  #ft-form textarea:focus {
    border-color: #bbb;
    box-shadow: 0 0 0 0 #fff,0 0 0 3px rgba(199,210,254,.5),0 0 #0000
  }
  #ft-form [type=checkbox],
  #ft-form [type=radio] {
    color: #4f46e5;
    box-shadow: none;
    border-radius: .25rem;
    border-color: #bbb;
    margin-right: .5rem
  }
  #ft-form [type=checkbox]:focus,
  #ft-form [type=radio]:focus {
    border-color: #bbb;
    box-shadow: 0 0 0 0 #fff,0 0 0 3px rgba(199,210,254,.5),0 0 #0000
  }
  #ft-form [type=radio] {
    border-radius: 100%
  }
  #ft-form .btns {
    text-align: center;
    margin-top: 0rem;
  }
  #ft-form .btns > input[type=button],
  #ft-form .btns > input[type=reset],
  #ft-form .btns > input[type=submit] {
    display: inline-block;
    box-shadow: 0 0 #0000,0 0 #0000,0 1px 2px 0 rgba(0,0,0,.05);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .6rem;
    padding-bottom: .6rem;
    line-height: 1.25rem;
    border-width: 1px;
    border-radius: .375rem;
    border-color: #bbb;
    background-color: #fff;
    cursor: pointer;
    margin-left: .5rem;
    font-weight: 700
  }
  #ft-form .btns > input[type=button]:focus,
  #ft-form .btns > input[type=reset]:focus,
  #ft-form .btns > input[type=submit]:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-color: #bbb;
    box-shadow: 0 0 0 2px #fff,0 0 0 4px rgba(199,210,254,.5),0 0 #0000
  }
  #ft-form .btns > input[type=submit] {
    background-color: #f3f4f5;
    transition: background-color .1s
  }
  #ft-form .btns > input[type=submit]:hover {
    background-color: #f8f9fa
  }

.section-form{
     
     position: relative;
     height: 80%;
     width: 100%;
     background-color: white;
     display: flex;
     justify-content: center;
     padding-right: 0px;
 }

 .form-background {
  width: 45%;
  position: relative;
  background-color: rgba(0, 0, 255, 0.5);
  border-style: solid;
  border-color: white;
  box-shadow: 0px 0px 3px 0px black;
}

.app-header-image{
  position: relative;
    height: 20%;
    display: flex;
    align-content: center;
    align-items: center;
    }

.make-app-header{

  position: relative;
    height: 106%;
    width: 38%;
    margin: auto;
    opacity: 0.6

}

.labelNames{

  font-family: 'Sansation-Regular';
    font-size: 18px;
}

.make-appointment{
  position: relative;
  font-family: 'Sansation-Bold';
  font-size: 35px;
}

.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 91%;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.bookAppointmentHomebutton{
  background: blue !important;
    color: white !important;
    font-size: 18px !important;
    position: absolute !important;
    margin-top: 5% !important;
    /* padding: 14px 118px !important; */
    margin-left: 15% !important;
    box-shadow: 0px 0px 5px 0px blue !important;
    font-family: 'Sansation-Bold';
}

.bookAppointmentHomebutton:hover{
  background: white !important;
    color: blue !important;
    font-size: 18px !important;
    position: absolute !important;
    margin-top: 5% !important;
    /* padding: 14px 118px !important; */
    margin-left: 15% !important;
    box-shadow: 0px 0px 5px 0px blue !important;
    font-family: 'Sansation-Bold';
}

.ant-form-item-appoint-home {
  margin-bottom: 40px !important;
  
}

.ant-input-number {
  
  width: 60% !important;
}

.ant-form-item .ant-select, .ant-form-item .ant-cascader-picker {
  width: 50% !important;
}

@media screen and (max-width: 1400px) {
  .section-form{
    height: 67%;
  }
} 

@media screen and (max-width:660px) {

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    font-size: 9px !important;
    line-height: 26px !important;

}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto !important;
  padding: 0 12px 5px 0 !important;
  line-height: 15px !important;
  font-size: 10px !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block !important;
  width: 11px !important;
  height: 30px !important;
  margin: 0 4px !important;
  padding: 17px 0px 0 !important;
  border: 0 !important;
  border-top: 2px solid #f0f0f0 !important;
  border-radius: 0 !important;
  transition: background 0.3s !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto !important;
  padding: 0 12px 5px 0 !important;
  line-height: 10px !important;
  font-size: 10px !important;
}

.ant-select {
  box-sizing: border-box !important;
  margin: 0 !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 10px !important;
  font-variant: tabular-nums !important;
  line-height: 1.5715 !important;
  list-style: none !important;
  font-feature-settings: 'tnum', "tnum" !important;
  position: relative !important;
  display: inline-block !important;
  cursor: pointer !important;
}

.ant-radio-button-wrapper {
  position: relative !important;
  display: inline-block !important;
  height: 32px !important;
  margin: 0 !important;
  padding: 0 0px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 10px !important;
  line-height: 30px !important;
  background: #fff !important;
  border: 1px solid #d9d9d9 !important;
  border-top-width: 1.02px !important;
  border-left-width: 0 !important;
  cursor: pointer !important;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s !important;
}

.ant-form-item-appoint-home {
  margin-bottom: 0 !important;
}

.ant-form-item-label > label {
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  height: 30px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 10px !important;
  font-family: 'Sansation-Bold';
}

.ant-input {
  font-size: 10px !important;
}



.ant-form-item-control-input {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  min-height: 24px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100% !important;
  height: 24px !important;
  padding: 0 11px !important;
}

.ant-picker-input > input {
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
  min-width: 0 !important;
  padding: 4px 11px !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 10px !important;
  
}

.bookAppointmentHomebutton {
  background: blue !important;
  color: white !important;
  font-size: 11px !important;
  position: absolute !important;
  margin-top: 13% !important;
  padding: 0px 0px !important;
  margin-left: 6% !important;
  box-shadow: 0px 0px 5px 0px blue !important;
  font-family: 'Sansation-Bold' !important;
  height: 22px !important;
}

.section-form {
  position: relative !important;
  height: 66% !important;
  width: 100% !important;
  background-color: white !important;
  display: flex !important;
  justify-content: center !important;
  padding-right: 0px !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {

  height: 0px !important;
}

.ant-picker-input > input {
  line-height: 0px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100% !important;
  height: 24px !important;
  padding: 0 11px !important;
}

.ant-form {
  box-sizing: border-box !important;
  margin: 0;
  /* padding: 0; */
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 13px !important;
  font-variant: tabular-nums !important;
  line-height: 0px !important;
  list-style: none !important;
  font-feature-settings: 'tnum', "tnum", "tnum" !important;
  margin-top: 25px !important;
  /* margin-left: -79px !important; */
  padding: 0px 0px 0px 14px !important;
  overflow-y: hidden;
}

.ant-form-item-control-input-content {
  flex: auto !important;
  max-width: 94% !important;
}

}


@media screen and (max-width: 660px) {
  .form-background {
    width: 80%;
    position: relative;
    background-color: rgba(0, 0, 255, 0.5);
    border-style: solid;
    border-color: white;
    box-shadow: 0px 0px 3px 0px black;
}
  
}

  
.navbar-service{
    height:96px
}

.service-img{
    height: 50%;

}

.service-top{
    height: 100%;
    padding-bottom: 0px;
    position: relative;
    background-repeat: round;
    justify-content: center;
    display: flex;

}

.service-text{
    position: absolute;
    top: 35%;
    left: 41%;
    font-family: 'Sansation-Bold';
    font-size: 52px;
    color: white;

}

.personal-section-wrapper{

    height: 100%;
    background-color: #4d7ee263;
}

.service-side-main{

    display: flex;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}



.service-details-main-wrapper{

    width: 80%;
}
.personal-main-wrapper{

    height: 50%;
    width: 90%;
    position: relative;
    top: 0%;
    right: 0%;
    padding: 50px 0px;
 }

.middle-personal-wrapper{
    position: relative;
    right: -5%;
    position: relative;
    right: -5%;
    padding: 28px 40px;

}

.middle-personal-wrapper:hover{
    box-shadow: 0px 0px 10px rgba(99, 99, 236, 0.952);
    /* border-style: dotted;
    border-color: white; */
    border-radius: 10px;
}


#services-header{
    position: relative;
    font-family: 'Sansation-Bold';
    font-size: 30px;
}

#paragraph{
    font-family: 'Sansation-Regular';
    line-height: 25px;
    text-align: justify;
}

.quick-access{
    width: 20%;
    position: relative;
}

.quick-link-header{
    position: relative;

    font-size: 33px;

}

.quick-links-middle-wrapper{
    height: 100%;
    position: relative;
    top: 7%;
    font-family: 'Sansation-Bold';
    font-size: 20px;
    text-align: center;

}

.ul-quicklinks{
    list-style-type: none;
    /* text-align: justify; */
    line-height: 63px;
    position: relative;
    margin: 0px 0px 0px 0px;
    padding-inline-start: 0px;
    padding: 20px 30px;

}

.li-quick-links{
    border-style: groove;
    text-align: center;
}

.li-quick-links:hover{

    background-color: rgba(255, 0, 0, 0.801);
    box-shadow: 0px 0px 10px black;
}

.footer-service-class{
    position: relative;
    margin-top: -20px;
}

#book {
    margin-top: 0px !important;
    width: 22% !important;
    font-size: 18px !important;
    left: 80%;
}

#book:hover{
    margin-top: 0px !important;
    width: 22% !important;
    font-size: 18px !important;
    left: 80%;
    background-color: blue;

}

.bookAppointmentSide{
    position: relative;
    height: 101% !important;
    left: 34% !important;
    font-family: 'Sansation-Bold' !important;
    background: #0000ffa8 !important;
    color: white !important;
}

.ant-form-item-side-appoint {
    margin-bottom: 40px !important;
}

@media screen and (max-width: 700px) {

    .service-side-main {
        display: flex;
        position: relative;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .service-details-main-wrapper {
        width: 100%;
    }

    .quick-access {
        width: 100%;
        height: 50%;
        position: relative;
        display: none;
    }

    #book {
        margin-top: 0px !important;
        width: 100% !important;
        font-size: 18px !important;
        left: 0% !important;
    }
    #book:hover{
        margin-top: 0px !important;
        width: 100% !important;
        font-size: 18px !important;
        left: 0% !important;
        background-color: blue;
    
    }

    .bookAppointmentSide{
        position: relative;
        height: 101% !important;
        left: 34% !important;
        font-family: 'Sansation-Bold' !important;
        background: #0000ffa8 !important;
        color: white !important;
    }
    
    
}